import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@common/services/user.service';
import { SpinnerService } from '@common/services/spinner.service';
import { MarketplaceProductService } from '@common/services/marketplace-product.service';


export enum stpActiveStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR'
}
@Component({
  selector: 'app-order-purchase-dialog',
  templateUrl: './order-purchase-dialog.component.html',
  styleUrls: ['./order-purchase-dialog.component.scss']
})
export class OrderPurchaseDialogComponent implements OnInit {

  productId: any;
  cusip: any;
  cusipLabel: string;
  tradeDeskId: any;
  programId: any;
  orderId: any;
  formData: any;
  permissions: any;
  showError: any;
  stpStatusActive: stpActiveStatus;

  constructor(private userService: UserService,
              private marketplaceProductService: MarketplaceProductService,
              private dialogRef: MatDialogRef<OrderPurchaseDialogComponent>,
              private spinnerService: SpinnerService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    if (data.registrationType && data.registrationType.toLowerCase() === 'reg s') {
      this.cusip = data.isin;
      this.cusipLabel = 'ISIN';
    } else {
      this.cusip = data.cusip;
      this.cusipLabel = 'CUSIP';
    }
    this.tradeDeskId = data.tradeDeskId;
    this.programId = data.programId;
    this.orderId = data.orderId;
    this.productId = data.productId;
  }

  ngOnInit() {
    this.permissions = this.userService.getUser()?.permissions;
    this.getOrderFormData();
    this.getStpStatus();
  }

  getOrderFormData() {
    this.spinnerService.showSpinner();
    if(this.orderId) {
      this.marketplaceProductService.getOrderFormEdit(this.orderId).subscribe(data => {
        if (!data) {
          this.showError = true;
        } else {
          this.formData = data;
        }
        this.spinnerService.hideSpinner();
      }, err => {
        this.showError = true;
        this.spinnerService.hideSpinner();
      });
    } else {
      this.marketplaceProductService.getOrderForm(this.productId, this.programId, this.tradeDeskId).subscribe(data => {
        if (!data) {
          this.showError = true;
        } else {
          this.formData = data;
        }
        this.spinnerService.hideSpinner();
      }, err => {
        this.showError = true;
        this.spinnerService.hideSpinner();
      });
    }
  }

  getStpStatus() {
    this.marketplaceProductService.getSTPActiveStatus(this.tradeDeskId, this.programId).subscribe( (res: boolean) => {
      this.stpStatusActive = res ? stpActiveStatus.ACTIVE : stpActiveStatus.INACTIVE;
    }, err => {
      this.stpStatusActive = stpActiveStatus.ERROR;
    });
  }
  close() {
    this.dialogRef.close();
  }
}
