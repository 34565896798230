import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '@common/services/user.service';
import { Router } from '@angular/router';
import { LoginUserInfo } from '../login.component';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { MatFormField } from '@angular/material/form-field';
import { NavigationConstants } from '@constants/navigation.constants';


const INVALID_LOGIN = 'The login credentials entered are incorrect. Please try again.';
const ERROR_LOGGING_IN = 'There was a problem logging in. Please try again later or contact luma support.';
const MISSING_CREDENTIALS = 'Please enter both email address & password to login.';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, AfterViewInit {

  @ViewChild('username') usernameFormField: MatFormField;
  @ViewChild('password') passwordFormField: MatFormField;

  @Input() isMaintenanceMode: boolean;
  @Input() userInfo: LoginUserInfo;
  @Output() messageEmitter = new EventEmitter();


  hidePassword = true;
  initialized = false;
  showSpinner = false;

  formGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    rememberMe: new FormControl(false)
  });

  constructor(public  userService: UserService,
              private router: Router) { }

  ngOnInit(): void {
    if(this.userService.rememberMeUserName !== null){
      this.formGroup.setValue({ username: this.userService.rememberMeUserName?.trim(), password: this.formGroup.get('password').value, rememberMe: !!this.userService.rememberMeUserName });
    }
    else if(this.userInfo) {
      let userName = '';
      if(this.userInfo?.username) {
        userName = this.userInfo.username;
      }
      this.formGroup.setValue({ username: userName.trim(), password: this.formGroup.get('password').value, rememberMe: this.userInfo?.rememberMeIndicator || false });
      this.formGroup.updateValueAndValidity();
    }
    if (this.isMaintenanceMode) {
      this.formGroup.disable();
    }
  }

  // need to update the outline gap for cases where google autofills and the mat-form-field-gap is sized based on the autofill text (which is smaller/different font)
  ngAfterViewInit() {
    setTimeout(() => this.usernameFormField.updateOutlineGap(), 500);
    setTimeout(() => this.passwordFormField.updateOutlineGap(), 500);
  }


  login() {
    this.formGroup.markAllAsTouched();
    const username = this.formGroup.get('username').value.trim();
    const password = this.formGroup.get('password').value;
    if(!username || !password) {
      this.emitMessage(MISSING_CREDENTIALS);
    } else if (!this.formGroup.disabled && username && password) {
      this.formGroup.disable();
      this.showSpinner = true;
      this.userService.login(username, password).pipe(
        switchMap(() => {
          return forkJoin([this.userService.getDefaultLandingPage(), this.userService.generateCst().pipe(catchError(err => null))]);
        })
      ).
      subscribe(([landingPagePayload, cst]) => {
        if(this.formGroup.get('rememberMe').value) {
          this.userService.rememberMeUserName = this.formGroup.get('username').value;
        } else {
          this.userService.rememberMeUserName = '';
        }
        this.emitMessage();
        const rememberMeControl = this.formGroup.get('rememberMe');
        this.userService.rememberMe(rememberMeControl.value).pipe(catchError(() => null)).subscribe((response: boolean) => {
          if(landingPagePayload?.login2DestinationURL?.startsWith('/')) {
            this.router.navigate([landingPagePayload.login2DestinationURL]);
          } else if(landingPagePayload?.login2DestinationURL && cst) {
            window.location.href = `${landingPagePayload.login2DestinationURL}&cst=${cst}`;
          } else {
            this.router.navigate(['dashboard']);
          }
          this.showSpinner = false;
        });
      }, (error) => {
        this.formGroup.enable();
        const splitError = error?.split(' - ');
        if(splitError?.length > 1) {
          this.emitMessage(splitError[1]);
        } else if(error?.toLowerCase()?.startsWith('bad credentials')) {
          this.emitMessage(INVALID_LOGIN);
        } else {
          this.emitMessage(ERROR_LOGGING_IN);
        }
        [this.formGroup.get('username'), this.formGroup.get('password')]
        .forEach(control => { control.setErrors({ incorrect: true }); });
        this.showSpinner = false;
      });
    }
  }

  resetPassword() {
    this.emitMessage();
    this.router.navigate([NavigationConstants.LOGIN],  { queryParams: { passwordReset: 'true' } });
  }

  emitMessage(message: string = null) {
    this.messageEmitter.emit({ message, icon: 'exclamation', color: '#af2a2a' }); // $luma-red
  }

  isFormDisabled() {
    return this.isMaintenanceMode || this.formGroup.disabled;
  }
}
