import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export enum AnimationDirections {
    RIGHT = 'right',
    LEFT = 'left',
}


export interface AnimatedDialogConfig extends MatDialogConfig {
    animation?: {
        basicAnimation?: AnimationDirections;
    };
    flyInDirection?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AnimatedDialogService {

    private readonly CSS_CLASSES = {
        RIGHT: {
            in: 'slide-right-in',
            out: 'slide-right-out',
            position: {
                right: '0'
            }
        },
        LEFT: {
            in: 'slide-left-in',
            out: 'slide-left-out',
            position: {
                left: '0'
            }
        }
    };

    constructor(private dialog: MatDialog) {

    }

    open<T, D = any, R = any>(component: ComponentType<T> | TemplateRef<T>,
                              animatedOptions: AnimatedDialogConfig): MatDialogRef<T, R> {

        // TODO - Make Map when directions are expanded (Top & Bottom)
        const direction = animatedOptions?.animation?.basicAnimation === AnimationDirections.LEFT
            ? this.CSS_CLASSES.LEFT : this.CSS_CLASSES.RIGHT;

        if (animatedOptions.animation) {
            const panelClass = ['basic-animation', direction.in];

            if (animatedOptions.panelClass) {
                if (typeof animatedOptions.panelClass === 'string') {
                    animatedOptions.panelClass = [animatedOptions.panelClass, ...panelClass];
                } else {
                    animatedOptions.panelClass.push(...panelClass);
                }
            } else {
                animatedOptions.panelClass = panelClass;
            }

            animatedOptions.position = {
                ...animatedOptions.position,
                ...direction.position
            };
        }

        const ref = this.dialog.open(component, animatedOptions);

        if (animatedOptions.animation) {
            const refClose = ref.close;

            const closeDialog = (dialogResult) => {
                ref.close = refClose;
                const classList = document.getElementsByClassName('basic-animation')[0];

                classList?.addEventListener('animationend', () => {
                    ref.close(dialogResult);
                });

                classList?.classList.add(direction.out);
            };

            ref.close = (dialogResult?: R) => closeDialog(dialogResult);
        }

        return ref;
    }
}
