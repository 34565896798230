import { Component, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ActivatedRoute, Router } from '@angular/router';
import { CSS_COLORS } from '@common/models/css-colors';
import { ProductInfo } from './models/product-info';
import { getInfoLabels, InfoLabelRows } from './models/product-overview-info-labels';
import { ERROR_PRODUCT } from './models/error-product';
import { Location } from '@angular/common';
import { CusipService } from '@common/services/cusip.service';
import { forkJoin, of } from 'rxjs';
import { CompanyLogoService } from '@creation-hub/services/company-logo.service';
import { OrderPurchaseDialogComponent } from '@product-marketplace/structured-products/order-purchase/order-purchase-dialog/order-purchase-dialog.component';
import { ISplitMenuItem } from '@common/components/split-button/split-button.component';
import { MarketplaceProductService } from '@common/services/marketplace-product.service';
import { MarketplaceUtilities } from '@common/utilities/marketplace-utilities';
import { catchError } from 'rxjs/operators';
import { DocumentDetails } from '@product-marketplace/common/models/document-details';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.scss']
})
export class ProductDetailsModalComponent {
  @Output() portfolioToLoad: string;
  // tslint:disable-next-line:variable-name
  private _apiError = false;
  productInfo: ProductInfo;
  showProductDetails = false;
  showSpinner = true;
  cusip;
  underliers = [];
  issuerLogoData;
  infoLabelRows: InfoLabelRows;
  mediumGrey = CSS_COLORS.MEDIUM_GREY;
  underlierTooltipIsOpen = false;
  noIssuerLogo = false;
  returnTypeIcon;
  returnTypeClass;
  basketTypeText;
  isBuyClosed;
  isBuyEnabled;
  productDocuments: DocumentDetails[] = [];
  downloadMainItem: ISplitMenuItem = {
    displayName: 'Download',
    val: 'this should do nothing hopefully'
  };
  documentListRetrievalError: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location,
    private cusipService: CusipService,
    private marketplaceProductService: MarketplaceProductService
  ) {
    this.getProductInfo(data.product);
    this.isBuyClosed = data.product.isBuyClosed;
    this.isBuyEnabled = data.product.isBuyEnabled;
  }

  get apiError(): boolean {
    return this._apiError;
  }

  set apiError(value: boolean) {
    if (value && !this._apiError) {
      this.afterProductInfoCall(ERROR_PRODUCT);
      if (this.cusip) {
        const urlTreeTest = this.router.createUrlTree(['..'], {
          relativeTo: this.route,
        });

        this.location.go(urlTreeTest.toString());
      }
    }
    this._apiError = value;
  }

  getReturnTypeIcon() {
    switch (this.productInfo.returnType) {
      case 'Income':
        return 'money-check-alt' as IconProp;
      case 'Growth':
        return 'analytics' as IconProp;
      case 'Growth and Income':
        return 'chart-bar' as IconProp;
      case 'Digital':
        return 'chart-network' as IconProp;
      default:
        return null;
    }
  }

  getReturnTypeColorClass() {
    switch (this.productInfo.returnType) {
      case 'Income':
        return {'tag-green': true};
      case 'Growth':
        return {'half-baked': true};
      case 'Growth and Income':
        return {'grid-blue-2': true};
      case 'Digital':
        return {'selective-yellow-5': true};
      default:
        return '';
    }
  }

  getBasketTypeText() {
    switch (this.productInfo.basketType) {
      case 'WoF':
        return 'Worst Of';
      case 'BoF':
        return 'Best Of';
      case 'Equal Weighting':
        return 'Equal Weight';
      case 'Custom':
        return 'Custom';
      default:
        return this.productInfo.basketType;
    }
  }

  createArray(length) {
    return Array(length).fill(0);
  }

  getProductInfo(modalData) {
    const calls = [
      this.cusipService.getProductDetails(modalData.cusip || modalData.isin).pipe(catchError(error => {
        return of(null);
      })),
      this.cusipService.getHockeystickChartDetails(modalData.cusip || modalData.isin).pipe(catchError(error => {
        return of(null);
      })),
    ];
    this.getDocuments();
    forkJoin(calls).subscribe(([productData, hockeyStickData]: any[]) => {
      if (hockeyStickData?.hasOwnProperty('fallbackImageUri') && !hockeyStickData?.fallbackImageUri.includes('platform-magic')) {
        modalData.hockeyStickFallbackImage = hockeyStickData?.fallbackImageUri;
      }
      modalData.hockeyStickChartData = hockeyStickData?.hockeystickPlotPoints;
      modalData = {
        ...modalData,
        strikeDate: productData?.strikeDate || null,
        finalValuationDate: productData?.finalValuationDate || null
      };
      this.afterProductInfoCall(modalData);
    });
  }

  getDocuments() {
    this.marketplaceProductService.getProductDocumentLinks(
      this.data.product.tradeDeskName,
      MarketplaceUtilities.getProductIdentifier(this.data.product?.registrationType, this.data.product?.cusip, this.data.product?.isin),
      this.data.product.productType.toUpperCase(),
      this.data.product.productId).subscribe({
      next: (documents: DocumentDetails[]) => {
        this.productDocuments = documents;
      },
      error: () => {
        this.productDocuments = null;
      }
    });
  }

  afterProductInfoCall(modalData) {
    this.productInfo = {
      issuer: modalData.issuer,
      issuerLogoUrl: modalData.issuerLogo,
      underliers: modalData.underlyers || [],
      basketType: modalData.basketType,
      annualizedCoupon: modalData.paymentRatePerAnnum,
      principalBarrierLevel: modalData.principalBarrier,
      principalBufferLevel: modalData.principalBuffer,
      paymentBarrier: modalData.couponBarrier,
      couponType: modalData.paymentType,
      paymentFrequency: modalData.paymentFrequency,
      callType: modalData.callType,
      noCallPeriod: modalData.noCallPeriod,
      callObservationFrequency: modalData.callObservationFrequency,
      tenor: modalData.term,
      tenorRemaining: '',
      tenorUnit: 'Years',
      initialStrikeDate: modalData.strikeDate,
      tradeDate: modalData.tradeDate,
      settlementDate: modalData.settlementDate,
      finalValuationDate: modalData.finalValuationDate,
      maturityDate: modalData.maturityDate,
      currency: modalData.currency,
      productIdentifier: modalData.cusip || modalData.isin,
      protectionType: modalData.protectionType,
      redemptionType: modalData.redemptionType,
      maximumReturn: modalData.maxReturn,
      minimumReturn: '', // TODO: check what this variable is or if it is in the object
      participationRate: modalData.upsideParticipationAboveDigitalReturn || modalData.upsideParticipationRate,
      digitalBarrier: modalData.digitalReturnBarrier,
      digitalReturn: modalData.digitalReturn,
      returnType: modalData.returnType,
      productStatus: modalData.productStatus,
      structureType: modalData.structureType,
      hockeyStickChartData: modalData.hockeyStickChartData,
      hockeyStickFallbackImage: modalData.hockeyStickFallbackImage,
    };
    this.showSpinner = false;
    this.showProductDetails = true;
    this.underliers = this.productInfo.underliers;
    if (this.underliers.length === 0) {
      this.underliers = modalData.underlyingTickers.split(',').map(underlier => ({symbol: underlier}));
      this.productInfo.underliers = this.underliers;
    }
    this.issuerLogoData = CompanyLogoService.CMS_URL + this.productInfo.issuerLogoUrl;
    this.infoLabelRows = getInfoLabels(this.productInfo);
    this.returnTypeIcon = this.getReturnTypeIcon();
    this.returnTypeClass = this.getReturnTypeColorClass();
    this.basketTypeText = this.getBasketTypeText();
  }

  openBuyDialog() {
    if (this.isBuyClosed || !this.isBuyEnabled) {
      return;
    }
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['confirmation-dialog', 'l-w840'];
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      cusip: this.productInfo.productIdentifier,
      productId: this.data.product.productId,
      tradeDeskId: this.data.product.tradeDeskId,
      programId: this.data.product.program.id
    };
    this.dialog.open(OrderPurchaseDialogComponent, dialogConfig);
  }

  openDocument(document: DocumentDetails) {
    this.marketplaceProductService.getDocument(document.documentUrl).subscribe(file => {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
