import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError, forkJoin } from 'rxjs';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@common/models/user';
import { UserMetadata } from '@common/models/usermetadata';
import { WidgetPermissions } from './ui.widget.permissions';
import { MatDialog } from '@angular/material/dialog';
import {
  MarketplaceProductService,
  MarketplaceUserProductsPermissions
} from '@common/services/marketplace-product.service';
import { CompanyService } from '@common/services/company-service';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { PreferenceService } from '@creation-hub/services/preference.service';
import { NavigationConstants } from '@constants/navigation.constants';

declare let pendo;

export enum InvalidUserAttribute {
  UnknownUUID = 'UnkownUser',
  UnknownTradeDesk = 'UnknownTradeDeskName'
}

export interface DefaultLandingPagePayload {
  websiteLogin: boolean;
  loginDestinationURL: string;
  login2DestinationURL: string;
}

interface TokenResponse {
  access_token: string;
  token_type: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  firstName?: string;
  lastName?: string;
  permissions?: [];
  name?: string;
  company?: string;
  locale?: string;
  jti?: string;
  uuid?: string;
}

interface Principal {
  name?: string;
  authorities?: [];
  details?: object;
  authenticated?: boolean;
  principal: {
    userId?: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    company?: string;
    locale?: string;
    authorities?: [];
    permissions?: [];
  };
}

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private renderer: Renderer2;

  USER_STORAGE_KEY = 'luma-user';
  initialized = false;
  private accessToken;
  private refreshToken;
  // These are not widget permissions
  private userPermissions: string[];

  private $userSubject = new BehaviorSubject<User>(null);
  $user: Observable<User> = this.$userSubject.asObservable();

  PREFERENCES_STORAGE_KEY = 'luma-preferences';
  AUTH_SERVICE_ROOT = `${environment.hosts.api_gateway}/api/auth-service`;
  PERMISSION_SERVICE_ROOT = `${environment.hosts.api_gateway}/api/permission-service`;
  USER_SERVICE_ROOT = `${environment.hosts.api_gateway}/api/user-service`;


  sessionTimeoutCheckingInterval;
  STORAGE_CHECKING_INTERVAL_MS = 5000;
  stopWindowStorageListener; // function that when called will end the window.storage listener
  LOGOUT_TRIGGERED = 'logout_triggered';
  SESSION_TIMEOUT_MESSAGE = 'Your session has expired.';

  // This variable is used to store a 'remember me' username for login for when a user updates their preference and doesn't reload the app before logging out.
  // Normally this is derived from the 'user_info' cookie added via the proxy. if they don't reload the app they won't have user_info
  rememberMeUserName = null;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
              private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              public router: Router,
              private dialog: MatDialog,
              private marketplaceProductService: MarketplaceProductService,
              private companyService: CompanyService,
              private rendererFactory: RendererFactory2,
              private preferenceService: PreferenceService,
              ) {
                this.renderer = rendererFactory.createRenderer(null, null);
                setTimeout(() => {
                  this.initialized = true;
                  }, 2000);
              }


  getToken() {
    return this.accessToken;
  }

  getPermissions() {
    return this.userPermissions;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getCompany() {
    return this.getUser() != null ? this.getUser().company : null;
  }

  getName() {
    return this.getUser() != null ? this.getUser().name : null;
  }

  getId() {
    return this.getUser() != null ? this.getUser().id : null;
  }

  getUuid() {
    return (this.getUser() != null && this.getUser().uuid != null) ? this.getUser().uuid : InvalidUserAttribute.UnknownUUID;
  }

  getCst() {
    return this.getUser()?.cst;
  }

  getUserWhiteLabelInfo(): WhiteLabelInfo {
    return this.getUser().whiteLabelInfo;
  }

  getPrimarySplitId() {
    return this.getUser().primarySplitId;
  }

  getSplitIds() {
    return this.getUser().splitIds;
  }

  // set-cookie for the rmt token is included in the api response header
  rememberMe(shouldRemember: boolean) {
    return this.http.put(`${this.AUTH_SERVICE_ROOT}/rmt/user/rememberMe?rememberMeIndicator=${shouldRemember}`, {})
  }

  generateCst() {
    return this.http.get(`${this.AUTH_SERVICE_ROOT}/cross-site/cst`, { responseType:'text' }).pipe(catchError(() => of(null)));
  }

  getDefaultLandingPage(): Observable<DefaultLandingPagePayload> {
    return this.http.get<DefaultLandingPagePayload>(`${this.AUTH_SERVICE_ROOT}/user/auth-config`).pipe(
      catchError( err => {
      return of({ login2DestinationURL: '/dashboard' }) as Observable<DefaultLandingPagePayload>;
    })
    );
  }

  login(username, password) {
    const clientId = environment.oauth.client_id;
    const userCredentials = new HttpParams({ fromObject: { username, password } }).toString();
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      }),
      params: new HttpParams({
        fromObject: {
          grant_type: 'password',
          client_id: clientId,
          scope: 'webclient' // todo externalize
        }
      })
    };

    return this.http.post<TokenResponse>(this.AUTH_SERVICE_ROOT + '/oauth/token', userCredentials, requestOptions)
      .pipe(
        mergeMap((token: TokenResponse) => {
          if(environment.localDevelopment) {
            this.storage.set(`${this.USER_STORAGE_KEY}-access`, token.access_token);
            this.storage.set(`${this.USER_STORAGE_KEY}-refresh`, token.refresh_token);
            this.storage.set(`${this.USER_STORAGE_KEY}-permissions`, token.permissions);
          }
          return this.handleTokenResponse(token);
        }),
        catchError((tokenError) => {
          this.clearCurrentUser();
          this.accessToken = null;
          this.refreshToken = null;
          return throwError(tokenError.error_description); // invalid login
        })
      );
  }

  /**
   * @param accessToken a valid access token, used to retrieve the full tokenResponse (including refresh token)
   * We use this method when we open the app in a new tab. The proxy sends over an access token and we use this to get the rest
   */
  getFullTokenResponseFromAccessToken(accessToken) {
    const httpHeaders: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this.http.get(this.AUTH_SERVICE_ROOT + '/token-info/user', {headers: httpHeaders, withCredentials: true}).pipe(
      mergeMap((tokenResponse: TokenResponse) => {
        return this.handleTokenResponse(tokenResponse);
      })
    );
  }


  /**
   * Given a tokenresponse, set up user (pendo, preferences, auxillary data about user that require additional api calls)
   * @param tokenResponse expected response from an endpoint that gives user data, jwt, and refresh token
   */
  handleTokenResponse(tokenResponse: TokenResponse) {
    this.accessToken = tokenResponse.access_token;
    this.refreshToken = tokenResponse.refresh_token;
    this.userPermissions = tokenResponse.permissions;

    const user = new User(tokenResponse.access_token);
    user.refreshToken = tokenResponse.refresh_token;
    user.preferences = this.loadPreferences(user);
    if(!environment.localDevelopment) {
      pendo.initialize({
        visitor: {
            id: user.id,
            uuid: user.uuid,
            full_name: tokenResponse.firstName + ' ' + tokenResponse.lastName
        },
        account: {
            id: tokenResponse.company
        }
      });
    }
    return this.updateAuxillaryData(user);
  }


    /**
     * Api calls to update user object data:
     * 1. Get Company White Label Data
     * 2. Add permissions to user
     * 3. Add user meta data for pendo
     * 4. Get Training Data
     * 5. Get user display data
     */
  updateAuxillaryData(loginUser: User) {
      return forkJoin([this.getWhiteLabelInfo(),
          this.fetchUserPermissions(),
          this.getUserMetadata(loginUser.id),
          this.getUserTrainingData(),
          this.getUserDisplayConfig(),
          this.getIsUserWholesaler(loginUser.company),
          this.getUserLogoutUrl()
        ])
        .pipe(switchMap( ([whiteLabelInfo, permissions, metaData, trainingDataResult, userDisplayConfig, isWholesaler, logoutUrl]) => {
      this.storePreferences(loginUser);
      if(permissions) {
        loginUser.permissions = permissions;
      } else {
        console.error('Could not get user permissions');
      }

      if(metaData && !environment.localDevelopment) {
        this.updateUserMetadata(loginUser, metaData);
      } else {
        console.error('Could not get user metadata.');
      }

      if (trainingDataResult) {
        loginUser.trainingData = trainingDataResult;
      }

      if (whiteLabelInfo) {
        loginUser.whiteLabelInfo = whiteLabelInfo;
      }

      if(userDisplayConfig) {
        loginUser.userDisplayConfig = userDisplayConfig;
      }

      loginUser.isWholesaler = isWholesaler;

      if(logoutUrl) {
        loginUser.logoutUrl = logoutUrl;
      }

      return this.http.get(`${environment.hosts.api_gateway}/api/dashboard-service2/pricing-tool-type`, {
        responseType: 'text'})
        .pipe(map(pricingToolType => {
          loginUser.pricingToolType = pricingToolType;
          this.$userSubject.next(loginUser);
          this.initWatchForSessionTimeout();
        }), catchError((err) => {
          loginUser.pricingToolType = null;
          this.$userSubject.next(loginUser);
          this.initWatchForSessionTimeout();
          return of(null);
        }));
    }));
  }

  fetchUserPermissions() {
    return this.http.post<string[]>(this.PERMISSION_SERVICE_ROOT + '/permission', Object.values(WidgetPermissions)).pipe( catchError(() => of(null) ));
  }

  getUserDisplayConfig() {
    return this.preferenceService.getUserDisplayConfig().pipe( catchError(() => of(null) ));
  }

  getIsUserWholesaler(company): Observable<boolean> {
    return this.marketplaceProductService.getIsUserWholesaler(company).pipe( catchError(() => of(false)));
  }

  private getUserMetadata(username: string): Observable<UserMetadata> {
    return this.http.get<UserMetadata>(this.USER_SERVICE_ROOT + '/usermetadata?userName=' + username).pipe(catchError(() => of(null)));
  }

  private getUserTrainingData(): Observable<MarketplaceUserProductsPermissions> {
    return this.marketplaceProductService.getUsersTraining();
  }

  private getWhiteLabelInfo(): Observable<any> {
    return this.companyService.getWhiteLabelInfo()
        .pipe(catchError(err => {
          return of(new WhiteLabelInfo());
        }));
  }

  updateUserMetadata(user: User, data: UserMetadata) {
    pendo.updateOptions({
      visitor: {
        id: user.id,
        uuid: user.uuid,
        first_name: data.firstName,
        last_name: data.lastName,
        full_name: data.name,
        role: data.primaryRole,
        sub_role: data.subRoles,
        cdtraining_status: data.cdTrainingStatus,
        cdtraining_completedate: data.cdTrainingDate,
        cdtraining_score: data.cdTrainingScore,
        notestraining_status: data.notesTrainingStatus,
        notestraining_completedate: data.notesTrainingDate,
        notestraining_score: data.notesTrainingScore,
        annuitiestraining_status: data.annuityTrainingStatus,
        annuitiestraining_completedate: data.annuityTrainingDate,
        annuitiestraining_score: data.annuityTrainingScore
      },
      account: {
        id: user.company,
        type: data.tradeDeskType
      }
    });
  }

  getUserLogoutUrl(): Observable<any> {
    return this.http.get<any>(`${this.AUTH_SERVICE_ROOT}/user/auth-logouturl`, {  responseType: 'text' as 'json'})
      .pipe(
        catchError(err => {
        return of(null);
      }));
  }
  // checks the user's refresh token at intervals to know when to log out a user (when their session has expired)
  initWatchForSessionTimeout() {
    this.sessionTimeoutCheckingInterval = setInterval(() => {
      const user = this.getUser();
      if (user && !user.isLoggedIn()) {
        this.logout(this.SESSION_TIMEOUT_MESSAGE);
      }
    }, this.STORAGE_CHECKING_INTERVAL_MS);

    this.stopWindowStorageListener?.();
    this.stopWindowStorageListener = this.renderer.listen('window', 'storage', this.onLogoutFromAnotherTab);
  }


  /**
   * Called from within auth.interceptor to get a valid access token when the current one fails using the refresh token
   * @returns access token
   */
  public refreshAccessToken(): Observable<TokenResponse> {
    const clientId = environment.oauth.client_id;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      }),
      params: new HttpParams({
        fromObject: {
          grant_type: 'refresh_token',
          client_id: clientId,
        }
      })
    };
    const grantPayload = `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}`;
    return this.http.post<TokenResponse>(this.AUTH_SERVICE_ROOT + '/oauth/token', grantPayload, requestOptions)
    .pipe(
      map((token) => {
        this.accessToken = token.access_token;
        return token;
      }),
      catchError((error: HttpErrorResponse) => {
        this.pingColdFusionLogout().subscribe( res => {});
        this.triggerLogoutAcrossTabs();
        if(window.location.pathname !== NavigationConstants.LOGIN) {
          this.navigateToLogin(false);
        }
        return throwError(error);
      })
    );
  }

  getUser(): User {
    return this.$userSubject.getValue();
  }

  getUserByUsername(username: string) {
    return this.http.get(this.USER_SERVICE_ROOT + '/user?userName=' + username);
  }

  getUserPermissions(): string[] {
    return this.$userSubject.getValue()?.permissions || [];
  }

  checkPasswordExpiration() {
    return this.http.get(`${this.AUTH_SERVICE_ROOT}/password/DaysUntilExpired`);
  }

  checkPasswordIsValid(newPassword: string, tempAccessToken: string) {
    const httpHeaders: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${tempAccessToken}`);
    return this.http.post(`${this.AUTH_SERVICE_ROOT}/password/validate`, { newPassword }, {headers: httpHeaders, withCredentials: true, responseType: 'text'})
      .pipe(catchError(err => {
      return of('error');
    }));
  }

  resetPassword(newPassword: string, verificationCode: string, tempAccessToken: string) {
    const httpHeaders: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${tempAccessToken}`);
    const resetPasswordObj = { verificationCode, newPassword };
    return this.http.post(`${this.AUTH_SERVICE_ROOT}/password/reset`, resetPasswordObj, {headers: httpHeaders, withCredentials: true});
  }

  sendResetPassword(username, tempAccessToken) {
    const httpHeaders: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${tempAccessToken}`);
    return this.http.get(`${this.AUTH_SERVICE_ROOT}/password/reset/email/${username}`, {headers: httpHeaders, withCredentials: true});
  }

  logout(messageToShowAfterLogout?) {
    this.router.navigate([NavigationConstants.LOGOUT], {skipLocationChange: true }).then(value => {
      this.removeRMT().subscribe(rmtRemoved => {
        this.pingColdFusionLogout().subscribe(res => {  // catchError happens in here so it will always execute revoketoken
          this.revokeToken().subscribe(next => {
            // setTimeout was added to simulate longer logout times - is not required, but leaving in
            setTimeout(() => {
              if(environment.localDevelopment) {
                this.storage.remove(`${this.USER_STORAGE_KEY}-access`);
                this.storage.remove(`${this.USER_STORAGE_KEY}-refresh`);
                this.storage.remove(`${this.USER_STORAGE_KEY}-permissions`);
              }
              this.triggerLogoutAcrossTabs();
              this.navigateToLogin(true, messageToShowAfterLogout);
            });
          }, err => {
            this.navigateToLogin(true, messageToShowAfterLogout);
          });
        });
      });
    });
  }

  // removed the RMT cookie IF the user has not opted in for 'remember me' on the login page
  // (we use the rmt to provide access tokens when opening new tabs when a user is logged in)
  removeRMT(): Observable<any>{
    return this.http.get(this.AUTH_SERVICE_ROOT + '/rmt/logout').pipe(catchError(err => of(null)));
  }

  revokeToken(): Observable<any> {
    return this.http.delete(this.AUTH_SERVICE_ROOT + '/tokens/current');
  }

  triggerLogoutAcrossTabs() {
    this.dialog.closeAll();
    localStorage.setItem(this.LOGOUT_TRIGGERED, 'logout'); // logout across tabs
    localStorage.setItem(this.LOGOUT_TRIGGERED, null);
  }

  navigateToLogin(logout: boolean, messageToShowAfterLogout = 'You\'ve been logged out') {
    if(this.getUser()?.logoutUrl) {
      window.location.href = this.getUser()?.logoutUrl;
    } else {
      let route = `${window.location.origin}/login`;
      route += logout ? `?loginMessage=${messageToShowAfterLogout}` : '';
      window.location.reload();
      window.location.href = route;
    }
  }

  clearCurrentUser() {
    this.$userSubject.next(null);
  }


  private pingColdFusionLogout() {
    return this.http.get(`${environment.hosts.portal}/cdfg/WebPages/deleteCFSession.cfm`).pipe(catchError(err => of(null)));
  }

  clearAccessTokenFromUrl() {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {access_token: null, refresh_token: null},
        replaceUrl: true,
        queryParamsHandling: 'merge'
      });
  }

  doesLocalUserHavePermission(permission) {
    return this.getUser().permissions.includes(permission);
  }


  onLogoutFromAnotherTab = (event) => {
    if (event.storageArea === localStorage && event.key === this.LOGOUT_TRIGGERED) {
      if(event.newValue === 'logout') {
        if ( window.location !== window.parent.location ) { // If an iframe: reload parent
          if(this.getUser()?.logoutUrl) {
            window.parent.location.href = this.getUser().logoutUrl;
          } else {
            window.parent.location.href = `${window.location.origin}/login`;
          }
        } else {
          this.navigateToLogin(false);
        }
      }
    }
  }

  stopWatchingForSessionTimeout() {
    clearInterval(this.sessionTimeoutCheckingInterval);
  }


  // local development
  async loadUserFromLocalStorage() {
    return new Promise<void>((resolve, reject) => {
      this.accessToken = this.storage.get(`${this.USER_STORAGE_KEY}-access`);
      this.refreshToken = this.storage.get(`${this.USER_STORAGE_KEY}-refresh`);
      this.userPermissions = this.storage.get(`${this.USER_STORAGE_KEY}-permissions`);
      if (this.accessToken && this.refreshToken) {
        const user = new User(this.accessToken);
        user.refreshToken = this.refreshToken;
        user.preferences = this.loadPreferences(user);
        this.updateAuxillaryData(user).subscribe(
          {
            next: () => {
              resolve();
            },
            error: () => {
              resolve();
            },
            complete: () => {
              resolve();
            }
          }
        );
      } else {
        if (window.location.pathname !== NavigationConstants.LOGIN) {
          this.navigateToLogin(false);
          reject();
        } else {
          resolve();
        }
      }
    });
  }

  // App related preferences in local storage

  getPreference(key: string) {
    return this.getUser() != null ? this.getUser().preferences[key] : null;
  }

  addPreference(key: string, value: any) {
    if (this.getUser() != null) {
      this.getUser().preferences[key] = value;
      this.storePreferences(this.getUser());
    }
  }

  private storePreferences(user: User) {
    if (user != null) {
      this.storage.set(`${this.PREFERENCES_STORAGE_KEY}-${user.id}`, user.preferences);
    }
  }

  private loadPreferences(user: User): object {
    const key = `${this.PREFERENCES_STORAGE_KEY}-${user.id}`;
    if (this.storage.has(key)) {
      return  this.storage.get(key);
    }
    return {};
  }


  ngOnDestroy() {
    this.stopWatchingForSessionTimeout();
    this.stopWindowStorageListener();
  }
}
