import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import { GridViewPreferenceModel, GridViewTypes } from '@common/models/grid-view-preference.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utilities } from '@common/utilities/utilities';
import { MiniNotificationComponent } from '@common/components/mini-notifcation/mini-notification.component';
import { SaveViewStateService, ViewWidgets } from '@common/components/save-view/save-view-state.service';
import { takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.scss']
})
export class SaveViewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('viewName') viewNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('saveViewMenu') saveViewMenu: ElementRef<HTMLDivElement>;
  @ViewChild('saveViewButton') saveViewButton: ElementRef<HTMLButtonElement>;
  @ViewChild('saveViewSection') saveViewSection: TemplateRef<HTMLSpanElement>;

  @Input() widgetType: ViewWidgets = ViewWidgets.PM_SP_GRID_MAIN;
  @Input() adminView = false;
  @Input() availableDesks: string[] = [];


  // @Input() selectedDesk = '';
  private _selectedDesk: string;
  get selectedDesk(): string {
    return this._selectedDesk;
  }

  @Input() set selectedDesk(desk: string) {
    if(desk && this._selectedDesk !== desk) {
      this._selectedDesk = desk;
      this.selectedDeskChange();
    }
  }

  @Input() maxCustomViews = 6;
  @Input() maxDeskViews = 3;

  userViews: GridViewPreferenceModel[] = [];
  deskViews: GridViewPreferenceModel[] = [];
  adminViews: GridViewPreferenceModel[] = [];
  newViewName: string;
  showNewViewName = false;
  showSavedViewsMenu = false;
  selectedView: GridViewPreferenceModel;
  gridViewTypes = GridViewTypes;
  marketplaceDesk: string;
  alive = true;

  selectedViewSub: Subscription;
  presetViewSub: Subscription;
  userViewSub: Subscription;
  deskViewSub: Subscription;
  resetViewsSub: Subscription;
  inputSelectionIsDupe = false;
  saveViewInputVal = '';

  constructor(
    private matSnackBar: MatSnackBar,
    private renderer: Renderer2,
    private saveViewStateService: SaveViewStateService) {
  }

  @HostListener('window:click', ['$event'])
  listenToWindowClick(event: MouseEvent) {
    const overlayActive = document.getElementsByClassName('cdk-overlay-backdrop')[0];
    if (!this.showSavedViewsMenu || (this.adminView && overlayActive)) {
      return;
    }
    if (!this.saveViewButton.nativeElement.contains(event.target as Node)
      && !this.saveViewMenu.nativeElement.contains(event.target as Node)
      && (this.saveViewSection != null && !this.saveViewSection.elementRef.nativeElement.isEqualNode(event.target as Node))) {
      this.showNewViewName = false;
      this.showSavedViewsMenu = false;
    }
  }


  ngOnInit() {
    this.getDeskInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgetType?.currentValue) {
      this.getDeskInfo();
    }
  }



  getDeskInfo() {
    if (!this.widgetType) {
      return;
    }

    this.presetViewSub?.unsubscribe();
    this.userViewSub?.unsubscribe();
    this.deskViewSub?.unsubscribe();
    this.selectedViewSub?.unsubscribe();
    this.resetViewsSub?.unsubscribe();

    this.resetViewsSub = this.saveViewStateService.$resetViews
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (widget: ViewWidgets) => {
          this.selectedView = null;
        }
      });


    this.selectedViewSub = this.saveViewStateService.getSelectedView1(this.widgetType)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: view => {
          this.selectedView = view;
        }
      });

    if (this.adminView) {
      this.selectedDesk = this.selectedDesk || GridViewTypes.lumaPreset.toString();
      this.updateLumaPresets();
      this.presetViewSub = this.saveViewStateService.getPresetView1(this.widgetType)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (views: GridViewPreferenceModel[]) => {
            this.adminViews = views;
          }
        });

      this.deskViewSub = this.saveViewStateService.getAdminDeskView1(this.widgetType).pipe(takeWhile(() => this.alive)).subscribe({
        next: (views: GridViewPreferenceModel[]) => {
          this.adminViews = views;
        }
      });
    } else {
      this.userViewSub = this.saveViewStateService.getUserView1(this.widgetType).pipe(takeWhile(() => this.alive)).subscribe({
        next: (views: GridViewPreferenceModel[]) => {
          this.userViews = views;
        }
      });

      this.deskViewSub = this.saveViewStateService.getDeskView1(this.widgetType).pipe(takeWhile(() => this.alive)).subscribe({
        next: (views: GridViewPreferenceModel[]) => {
          this.deskViews = views;
        }
      });

      this.saveViewStateService.getUserView();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  viewRadioButtonClick(view: GridViewPreferenceModel): void {
    this.saveViewStateService.viewSelected(this.widgetType, view);
  }

  toggleViewNameRow(section: GridViewTypes): void {
    setTimeout(() => {
      this.showNewViewName = !this.showNewViewName;
      if (this.showNewViewName) {
        setTimeout(() => {
          this.viewNameInput.nativeElement.focus();
        }, 50);
      }
    });
  }

  toggleMenu(): void {
    this.showSavedViewsMenu = !this.showSavedViewsMenu;
  }

  saveView(inputVal: string, section: GridViewTypes): void {
    if (this.checkForDupe(inputVal, section)) {
      Utilities.launchSnackBar(MiniNotificationComponent.getErrorMessage('Duplicate view names not accepted'), this.matSnackBar);
      return;
    }

    const desk = (section === GridViewTypes.desk
      && this.selectedDesk !== GridViewTypes.lumaPreset.toString()) ? this.selectedDesk : null;

    this.saveViewStateService.saveNewView({
      widgetType: this.widgetType,
      viewName: inputVal,
      section,
      desk,
      adminView: this.adminView
    });
    this.toggleViewNameRow(null);
  }

  checkForDupe(inputVal: string, section: GridViewTypes): boolean {
    if (inputVal == null || inputVal === '') {
      return;
    }
    let viewArray;

    switch (section) {
      case GridViewTypes.lumaPreset:
        viewArray = this.adminViews;
        break;
      case GridViewTypes.desk:
        viewArray = (this.adminView) ? this.adminViews : this.deskViews;
        break;
      case GridViewTypes.user:
        viewArray = this.userViews;
        break;
    }

    this.inputSelectionIsDupe = viewArray?.map((view: GridViewPreferenceModel) => view.viewName).includes(inputVal);
    return this.inputSelectionIsDupe;
  }

  deleteViewEvent(view: GridViewPreferenceModel): void {
    this.saveViewStateService.deleteView(view);
  }



  selectedDeskChange(): void {
    const isLumaPreset = this.selectedDesk === GridViewTypes.lumaPreset.toString();
    if (this.adminView) {
      if (isLumaPreset) {
        this.updateLumaPresets();
      } else {
        this.saveViewStateService.getAdminView(this.selectedDesk);
      }
    } else if (!isLumaPreset) {
      this.saveViewStateService.getDeskView(this.selectedDesk);
    }
  }

  updateLumaPresets(): void {
    this.saveViewStateService.getPresetViews();
  }

  resetSavedViews(): void {
    if(!this.selectedView) {
      return;
    }
    const widgetType = this.selectedView.widgetType;
    this.saveViewStateService.resetViews1(widgetType);
  }
}
