import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';

declare global {
  interface Window { dataLayer: any;}
}

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.scss']
})
export class GoogleAnalyticsComponent implements OnInit {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {
    this.googleAnalyticsService.runMain(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    this.googleAnalyticsService.creation(environment.googleAnalytics.uCode);
  }

  ngOnInit() {

    const node = document.createElement('script');
    node.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics.uCode}`;
    node.type = 'text/javascript';
    node.async = true;
    // tslint:disable-next-line: deprecation
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    const pusher = document.createElement('script');
    pusher.type = 'text/javascript';
    pusher.async = true;
    // tslint:disable-next-line: deprecation
    pusher.charset = 'utf-8';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push('js', new Date());
    window.dataLayer.push('config', environment.googleAnalytics.uCode);
    document.getElementsByTagName('head')[0].appendChild(pusher);

    const mainTag = document.createElement('script');
    mainTag.src = `https://www.google-analytics.com/analytics.js`;
    mainTag.type = 'text/javascript';
    mainTag.async = true;
    // tslint:disable-next-line: deprecation
    mainTag.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(mainTag);

  }
}
