import { Injectable } from '@angular/core';

// tslint:disable-next-line: ban-types
declare let ga: Function; // Declare ga as a function
// tslint:disable-next-line: ban-types
declare let main: Function;


@Injectable()
export class GoogleAnalyticsService {

  constructor() { }

  public runMain(i,s,o,g,r) {
    const gaOLit='GoogleAnalyticsObject';
    i[gaOLit]=r;
    // tslint:disable-next-line: only-arrow-functions
    i[r]=   i[r] || function() {
      (i[r].q=i[r].q||[]).push(arguments);
    },
    i[r].l=new Date();
  }

  public creation(theCode: string) {
  ga('create', theCode, 'auto');
}

  public eventEmitter(eventCategory: string, eventAction: string,
                      eventLabel: string = null, eventValue: number = null) {
    ga('send', 'event', {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue
    });


  }
}
