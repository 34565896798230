import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ProductInfo } from '@common/components/product-details-modal/models/product-info';
import { LONG_DASH } from '@common/utilities/utilities';
import { CSS_COLORS } from '@common/models/css-colors';

class ProductInfoLabelConfig {
  iconColor?: (ProductInfo) => string;
  icon?: (ProductInfo) => IconProp;
  text?: (ProductInfo) => string;
  label: (ProductInfo) => string;
}

export class ProductInfoLabel {
  iconColor?: string;
  icon?: IconProp;
  text?: string;
  label: string;
}

export class InfoLabelRows {
  row1: ProductInfoLabel[];
  row2: ProductInfoLabel[];
}

const STATUS_INFO_LABEL: ProductInfoLabelConfig = {
  iconColor: (productInfo: ProductInfo) => {
    if(productInfo.productStatus.toUpperCase() === 'LIVE' && productInfo.productStage.toUpperCase() === 'POST TRADE') {
      return CSS_COLORS.LUMA_YELLOW_GREEN;
    } else {
      return CSS_COLORS.LUMA_LINK;
    }
  },
  icon: (productInfo: ProductInfo) => {
    if(productInfo.productStatus.toUpperCase() === 'LIVE' && productInfo.productStage.toUpperCase() === 'POST TRADE') {
      return ['far','heart-rate'];
    } else {
      return ['far','list-alt'];
    }
  },
  label: (productInfo: ProductInfo) => {
    if(productInfo.productStatus.toUpperCase() === 'LIVE' && productInfo.productStage.toUpperCase() === 'POST TRADE') {
      return 'Live Product';
    } else {
      return 'Pre-Trade';
    }
  }
};

const CALL_TYPE_LABEL: ProductInfoLabelConfig = {
  icon: (productInfo: ProductInfo) => {
    switch (productInfo.callType?.toUpperCase()) {
      case 'ISSUER':
        return 'landmark';
      case 'AUTO':
        return 'sync-alt';
      case 'AUTOCALL STEP':
        return 'sync-alt';
      default:
        return 'calendar-times';
    }
  },

  label: (productInfo: ProductInfo) =>  {
    switch (productInfo.callType?.toUpperCase()) {
      case 'ISSUER':
        return 'Issuer Callable';
      case 'AUTO':
        return 'Autocallable';
      case 'AUTOCALL STEP':
        return 'Autocallable';
      default:
        return 'Not Callable';
    }
  }
};

const COUPON_TYPE_LABEL: ProductInfoLabelConfig = {
  icon: (productInfo: ProductInfo) => ['far', 'file-invoice-dollar'],
  label: (productInfo: ProductInfo) =>  'Coupon'
};

const DOWNSIDE_TYPE_LABEL: ProductInfoLabelConfig = {
  icon: (productInfo: ProductInfo) => {
    switch (productInfo.protectionType?.toUpperCase()) {
      case 'BARRIER':
        return 'container-storage';
      case 'BUFFER':
        return 'layer-group';
      case 'GEARED BUFFER':
        return 'cogs';
      case 'GEARED BARRIER':
        return 'cogs';
      case 'FULL':
        return 'user-shield';
      case 'NO PRINCIPAL PROTECTION':
        return 'user-unlock';
      case 'PARTIAL':
        return 'users-medical';
      default:
        return 'container-storage';
    }
  },
  label: (productInfo: ProductInfo) =>  {
    switch (productInfo.protectionType.toUpperCase()) {
      case 'BARRIER':
        return 'Barrier';
      case 'BUFFER':
        return 'Buffer';
      case 'GEARED BUFFER':
        return 'Buffer with Gearing';
      case 'GEARED BARRIER':
        return 'Barrier with Gearing';
      case 'FULL':
        return 'Fully Protected';
      case 'NO PRINCIPAL PROTECTION':
        return 'No Protection';
      case 'PARTIAL':
        return 'Partial Protection';
      default:
        return '';
    }
  }
};

const MAX_RETURN_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.maximumReturn,
  label: (productInfo: ProductInfo) =>  'Max Return'
};
const UNCAPPED_MAX_RETURN_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => 'Uncapped',
  label: (productInfo: ProductInfo) =>  'Max Return'
};
const ANNUALIZED_COUPON_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.annualizedCoupon,
  label: (productInfo: ProductInfo) =>  'Annualized Coupon'
};
const DIGITAL_BARRIER_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => {
    const barrier = +productInfo.digitalBarrier.replace('%', '');
    let returnString = productInfo.digitalBarrier;
    if(barrier === 100) {
      returnString += ' (ATM)';
    } else if(barrier < 100) {
      returnString += ' (ITM)';
    } else {
      returnString += ' (OTM)';
    }
    return returnString;
  },
  label: (productInfo: ProductInfo) =>  'Digital Barrier'
};
const PAYMENT_FREQUENCY_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.paymentFrequency,
  label: (productInfo: ProductInfo) =>  'Coupon Frequency'
};
const PRINCIPAL_BARRIER_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.principalBarrierLevel,
  label: (productInfo: ProductInfo) =>  'Barrier Level'
};
const PRINCIPAL_BUFFER_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.principalBufferLevel,
  label: (productInfo: ProductInfo) =>  'Buffer Level'
};
const PARTICIPATION_RATE_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.participationRate,
  label: (productInfo: ProductInfo) =>  'Participation Rate'
};
const DIGITAL_RETURN_LABEL: ProductInfoLabelConfig = {
  text: (productInfo: ProductInfo) => productInfo.digitalReturn,
  label: (productInfo: ProductInfo) =>  'Digital Coupon Rate'
};

export function getInfoLabels(productInfo: ProductInfo): InfoLabelRows {
  if(productInfo.returnType === LONG_DASH) {
    return {
      row1: [],
      row2: []
    };
  }
  const row1: ProductInfoLabelConfig[] = [ STATUS_INFO_LABEL, CALL_TYPE_LABEL ];
  const row2: ProductInfoLabelConfig[] = [];

  if(productInfo.returnType === 'Income' && productInfo.annualizedCoupon) {
    row1.push(COUPON_TYPE_LABEL);
    row2.push(ANNUALIZED_COUPON_LABEL);
  }

  if(productInfo.protectionType) {
    row1.push(DOWNSIDE_TYPE_LABEL);
  }

  if(productInfo.returnType === 'Income' && productInfo.paymentFrequency) {
    row2.push(PAYMENT_FREQUENCY_LABEL);
  }

  if(['Growth', 'Growth and Income'].includes(productInfo.returnType)) {
    if(['Barrier', 'Geared Barrier'].includes(productInfo.protectionType)) {
      row2.push(PRINCIPAL_BARRIER_LABEL);
    }
    if(['Buffer', 'Geared Buffer'].includes(productInfo.protectionType)) {
      row2.push(PRINCIPAL_BUFFER_LABEL);
    }
  }

  if(['Growth', 'Growth and Income'].includes(productInfo.returnType) && productInfo.participationRate) {
    row2.push(PARTICIPATION_RATE_LABEL);
  }

  if(productInfo.maximumReturn) {
    row2.push(MAX_RETURN_LABEL);
  } else if(['Growth', 'Growth and Income', 'Digital'].includes(productInfo.returnType)) {
    row2.push(UNCAPPED_MAX_RETURN_LABEL)
  }

  if(productInfo.returnType === 'Digital' && productInfo.digitalReturn) {
    row2.push(DIGITAL_RETURN_LABEL)
  }

  if(productInfo.returnType === 'Digital' && productInfo.digitalBarrier) {
    row2.push(DIGITAL_BARRIER_LABEL);
  }

  if(row2.length > 4) {
    row2.splice(4, row2.length - 4); //TODO: figure out a better solution for handling lots of info labels
  }

  return {
    row1: row1.map(infoLabel => initInfoLabel(productInfo, infoLabel)),
    row2: row2.map(infoLabel => initInfoLabel(productInfo, infoLabel))
  };
}

function initInfoLabel(productInfo: ProductInfo, infoLabel: ProductInfoLabelConfig): ProductInfoLabel {
  return {
    iconColor: infoLabel.iconColor ? infoLabel?.iconColor(productInfo) : null,
    icon: infoLabel.icon ? infoLabel?.icon(productInfo) : null,
    text: infoLabel.text ? infoLabel?.text(productInfo) : null,
    label: infoLabel.label(productInfo)
  };
}
