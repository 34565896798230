import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { UserService } from '@common/services/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addToken, shouldAddToken } from '@common/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class InternalErrorInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (shouldAddToken(request)) {
      request = addToken(request, this.userService.getToken());
    }
    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        const responseCategory = +error.status.toString()[0];
        if (responseCategory === 5) {
          error.error.message = 'Sorry, something went wrong. Please try again later or contact Luma support at 844-844-5862 or support@lumafintech.com';
        }
        return throwError(error);
      }));
  }
}
