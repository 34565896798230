import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { takeWhile, filter } from 'rxjs/operators';
import { UserService, InvalidUserAttribute } from '@common/services/user.service';
import { environment } from '@environments/environment';
import { NavService } from '@common/services/nav.service';
import { onMainContentChange } from './animations/animations';
import { Utilities } from '@common/utilities/utilities';
import { SpinnerService } from '@common/services/spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { CompanyService } from '@common/services/company-service';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { SessionService } from './session.service';
import { NavigationConstants } from '@constants/navigation.constants';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';

declare var dataLayer;
declare let dT_;

export const USER_TOKEN_COOKIE ='user_token';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange]
})
export class AppComponent implements OnInit, OnDestroy {
  alive = true;
  @ViewChild('container') public container: ElementRef;
  iFrameRoutes = [
    '/learning-center/sso',
    '/luma-creation-hub',
    '/luma-creation-hub/overview',
    '/luma-creation-hub/draft-request',
    '/product-marketplace/order-form',
    '/product-marketplace/view-order-report',
    '/sso-required'
  ];
  insideIframe = false;

  whiteLabelInfo: WhiteLabelInfo;

  sideNavState: string;
  showHeader = false;
  showFooter = false;
  showSidenav = false;
  userCanViewNewNav = false;

  spinnerTimeout;

  urlActions = [
    {
      url: 'product-marketplace/structured-products',
      action: 'enterPM'
    },
    {
      url: 'lifecycle-manager/overview',
      action: 'enterLM'
    },
    {
      url: 'dashboard',
      action: 'enterDB'
    },
    {
      url: 'earning-center/overview',
      action: 'enterLC'
    }
  ];

  constructor(
    private titleService: Title,
    private router: Router,
    private companyService: CompanyService,
    private userService: UserService,
    private http: HttpClient,
    private navService: NavService,
    private renderer2: Renderer2,
    private sessionService: SessionService,
    private spinnerService: SpinnerService) {

    this.insideIframe = this.insideIframeCheck();

    // start dynatrace tracking
    if(environment.isDynatraceEnabled) {
      if(typeof dT_ !== 'undefined' && dT_.initAngularNg){dT_.initAngularNg(http, HttpHeaders);}
    }

    this.navService.sideNavState.pipe(takeWhile(() => this.alive)).subscribe(res => this.sideNavState = res);
    this.navService.showHeader.pipe(takeWhile(() => this.alive)).subscribe(res => this.showHeader = res);
    this.navService.showFooter.pipe(takeWhile(() => this.alive)).subscribe(res => this.showFooter = res);
    this.navService.showSidenav.pipe(takeWhile(() => this.alive)).subscribe(res => this.showSidenav = res);
  }

  hideShowCFrame(cleanPath) {
    if(cleanPath === NavigationConstants.LOGIN || cleanPath === NavigationConstants.LOGOUT || this.insideIframe) {
      this.navService.showHeader.next(false);
      this.navService.showSidenav.next(false);
      this.navService.showFooter.next(false);
    } else if(cleanPath.indexOf('creation-hub-ubs') !== -1) {
      this.navService.showSidenav.next(false);
      this.navService.showFooter.next(false);
    } else {
      this.navService.showHeader.next(true);
      this.navService.showSidenav.next(true);
      this.navService.showFooter.next(true);
    }
  }

  ngOnInit() {
    this.userService.$user.pipe(filter(user => user != null),takeWhile(_=> this.whiteLabelInfo == null && this.alive)).subscribe(user => {
      if (user != null) {
        this.userCanViewNewNav = user?.canAccess(WidgetPermissions.MenuCanViewNewNav);
        this.whiteLabelInfo = user.whiteLabelInfo;
      }
    });

    this.sessionService.filteredStartUrl.pipe(takeWhile(() => this.alive)).subscribe((cleanUrl: string) => {
      this.updateTitleAndShowSpinner(cleanUrl, true);
      this.spinnerTimeout = setTimeout(() => {
        this.spinnerService.showSpinner();
      }, 33);
    });

    this.sessionService.filteredNavEnd.pipe(takeWhile(() => this.alive)).subscribe((navigationEnd: NavigationEnd) => {
      const cleanUrl = Utilities.cleanUrl(navigationEnd.url);
      this.updateTitleAndShowSpinner(cleanUrl);
      this.hideShowCFrame(cleanUrl);
    });

    this.sessionService.filteredNavEnd.pipe(takeWhile(() => this.alive)).subscribe({next: this.runGoogleAnalyticsOnRouteChange});
    this.sessionService.filteredNavEnd.subscribe({next: () => {
        if (this.spinnerTimeout != null) {
          clearTimeout(this.spinnerTimeout);
        }
      }});
  }

  private updateTitleAndShowSpinner(cleanUrl, showSpinner = false) {
    if(cleanUrl=== NavigationConstants.LOGIN || window.location.pathname === NavigationConstants.LOGIN) {
      this.titleService.setTitle('Luma Platform Log In');
    } else {
      this.titleService.setTitle('Luma');
      if (showSpinner) {
        this.spinnerService.showSpinner();
      }
    }
  }

  scrollTop(event) {
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
  }

  insideIframeCheck() {
    if(window.location.pathname === '/product-marketplace/view-order-report') {
      this.renderer2.setStyle(this.container.nativeElement, 'width', '93%');
      this.renderer2.setStyle(this.container.nativeElement, 'height', '200px');
    }
    return (Utilities.foundInArray(this.iFrameRoutes, window.location.pathname) || window.location.href.includes(`iframe=true`));
  }

  // triggers google analytics
  runGoogleAnalyticsOnRouteChange = (routerEvent: RouterEvent) => {
    if (environment.googleAnalytics.isOn) {
      if (routerEvent instanceof NavigationEnd) {
        const matchedAction = this.urlActions.find(e => {
          return routerEvent.urlAfterRedirects.endsWith(e.url);
        });
        const action = matchedAction ? matchedAction.action : '';
        if (action) {
          dataLayer?.push({
            pageview: routerEvent.urlAfterRedirects,
            userid: this.userService.getUuid(),
            tradedeskname: this.userService.getCompany() ? this.userService.getCompany() : InvalidUserAttribute.UnknownTradeDesk,
            timestamp: new Date(),
            event: action
          });
        }
      }
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
