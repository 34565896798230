import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { NavService } from '@common/services/nav.service';
import { clearAccessTokenFromUrl } from '@common/auth-token';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@common/models/user';
import { DialogComponent } from './dialog/dialog.component';
import { DisclaimerDialogComponent } from './disclaimer-dialog/disclaimer-dialog.component';
import { DashboardMessage } from '@common/models/dashboard-message';
import { DashboardMessageService } from '@common/services/dashboard-message.service';
import { IncompleteTrainingsDialogComponent } from './incomplete-trainings-dialog/incomplete-trainings-dialog.component';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  creationHubLink: {url?: string, route?: string};
  user: User;
  dashboardMessage: DashboardMessage;
  sideNavState: string;
  showSideNav: boolean;
  userSub;

  constructor(
    private dialog: MatDialog,
    private dashboardMessageService: DashboardMessageService,
    private userService: UserService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.userSub = this.userService.$user.subscribe(user => this.user = user);
    this.navService.getPricingToolConfigObservable().subscribe(res => this.creationHubLink = res.cardLink);
    this.navService.sideNavState.subscribe(res => this.sideNavState = res);
    this.navService.showSidenav.subscribe(res => this.showSideNav = res);
  }

  ngOnInit(): void {
    clearAccessTokenFromUrl(this.router, this.route);
  }

  lifecycleNavigation() {
    if(this.user && this.user.canAccess('menu.lifecycle_manager')) {
      this.router.navigate(['/lifecycle-manager']);
    } else {
      this.showNoAccessMessage();
    }
  }

  learningCenterNavigation() {
    if(this.user?.canAccess(WidgetPermissions.AccessLearningCenter)) {
      this.router.navigate(['/learning-center']);
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: 'Your account does not have access to the learning center.',
        title: 'No Access',
        singleButton: true,
      };
      dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
      this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
    }
  }

  marketplaceNavigation() {
    const trainingData = this.userService.getUser().trainingData;
    if (trainingData?.complete) {
      if (trainingData?.isAnnuityOnly()) {
        this.router.navigate([`/product-marketplace/annuity-products`]);
      } else {
        this.router.navigate([`/product-marketplace/structured-products-2.0`]);
      }
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['confirmation-dialog', 'l-w500'];
      this.dialog.open(IncompleteTrainingsDialogComponent, dialogConfig);
    }
  }

  showDisclaimer(): MatDialogRef<DisclaimerDialogComponent> {
    if (!this.userService.getPreference(this.dashboardMessage.messageKey)) {
      return this.dialog.open(DisclaimerDialogComponent, {
        width: '25em',
        data: {
          dashboardMessage: this.dashboardMessage
        }
      });
    }
  }

  navigateToCreationHub() {
    if (this.creationHubLink.url) {
      this.navService.navigateWithCst(this.creationHubLink.url)
    } else {
      this.router.navigate([this.creationHubLink.route]);
    }
  }

  showNoAccessMessage() {
    return this.dialog.open(DialogComponent, {
      width: '25em',
      data: {
        title: 'No Access',
        message: 'Your access to this module is coming soon, please contact Luma Support for more information.'
      }
    });
  }

  toggleSideNavOnLModuleClick() {
    if (this.showSideNav === true && this.sideNavState === 'open') {
      this.navService.showSidenav.next(true);
      this.navService.sideNavState.next('open');
    }
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe();
  }
}
