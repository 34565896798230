import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
export interface CompanyLogo {
  url?: string;
  logoUrl?: string;
  width: number;
  height: number;
  localLogo?: boolean;
}

const defaultDimension = { width: 36, height: 36 };


const companies: { [id: string]: CompanyLogo } = {
  BofA: { url: 'bofa_logo.png',...defaultDimension,},
  BARC: { url: 'barc_logo.png', ...defaultDimension },
  BNC: { url: 'bnc_logo.png', ...defaultDimension },
  BNP: { url: 'bnp_logo.png', ...defaultDimension },
  BNS: { url: 'bns_logo.png', ...defaultDimension },
  CIBC: { url: 'cibc_logo.jpg', ...defaultDimension },
  CS: { url: 'cs_logo.png', ...defaultDimension },
  Citi: { url: 'citi_logo.png', ...defaultDimension},
  DB: { url: 'db_logo.png', ...defaultDimension },
  GS: { url: 'gs_logo.png', ...defaultDimension},
  HSBC: { url: 'hsbc_logo.png', ...defaultDimension },
  IFC: { url: 'ifc_logo.png', ...defaultDimension },
  // IS: { url: 'isyatirim_logo.png.com.tr', ...defaultDimension },
  JPM: { url: 'jpm_logo.png', ...defaultDimension },
  MS: {url: 'ms_logo.png', ...defaultDimension},
  RBC: { url: 'rbc_logo.png', ...defaultDimension },
  SEK: { url: 'sek_logo.png', ...defaultDimension },
  SG: { url: 'socgen_logo.png', ... defaultDimension },
  STI: { url: 'sti_logo.png', ...defaultDimension },
  TD: { url: 'td_logo.png', ...defaultDimension },
  WFC: { url: 'wf_logo.png', ...defaultDimension },
  UBS: { url: 'ubs_logo.png', ...defaultDimension},
  REMOVE: {url: 'icon-remove.png', ...defaultDimension}
};

@Injectable({ providedIn: 'root' })
export class CompanyLogoService {

  constructor() {}
  static ENV_MAP = {
    PROD: '',
    STAGING: 'staging.',
    UAT: 'buat.',
    QA: 'bqa.',
    DEV: 'bdev.',
    DEFAULT: 'bdev.'
  };
  static ENVIRONMENT_UPPER = environment.environmentName?.toUpperCase();
  static get CMS_URL() {
    const subDomain = CompanyLogoService.ENV_MAP[CompanyLogoService.ENVIRONMENT_UPPER];
    let upper = CompanyLogoService.ENVIRONMENT_UPPER;
    if (environment.environmentName === 'default' && environment.localDevelopment) {
      upper = 'DEV';
    }
    return `https://app.${subDomain}lumafintech.com/cms-assets/${upper}/images/company-logos/`;
  }
  private BASE_URL = '//img.lumafintech.com/assets/gwim/logos/';

  getLogoDetails(acronym: string): any {
    const company = companies[acronym];
    return !company ? undefined : {
      logoUrl: (company.localLogo ? '../assets/logo/' : this.BASE_URL) + company.url,
      ...company
    };
  }

}
