import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from '@environments/environment';
import { HttpServiceUtils } from './http-services.utils';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {
  private API_ROOT = `${environment.hosts.api_gateway}/api/preference-service/`;

  // GRID CUSTOM VIEWS
  private GET_ALL_VIEWS = this.API_ROOT + 'customgridviews?tablename=';
  private SAVE_VIEW = this.API_ROOT + 'customgridviews';
  private DEFAULT_VIEW = this.API_ROOT + 'customgridviews/defaultview?tablename=';

  // USER CUSTOM
  private GET_USER_VIEWS = this.API_ROOT + 'customuserviews';
  private DELETE_USER_VIEW = this.API_ROOT + 'customuserviews/';

  // USER GROUPINGS
  private GET_GROUPINGS = this.API_ROOT + 'grouping';

  // EMAIL CONFIG
  private EMAIL_CONFIG = this.API_ROOT + 'email';

  // DISPLAY CONFIG
  private DISPLAY_CONFIG = this.API_ROOT + 'display';

  constructor(private http: HttpClient){}

  saveView(createViewCommand) {
    return this.http.post<any[]>(this.SAVE_VIEW, createViewCommand, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  updateView(createViewCommand) {
    return this.http.patch<any[]>(this.SAVE_VIEW, createViewCommand, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  getAllViews(user, tableName): Observable<any> {
    return this.http.get<any>(this.GET_ALL_VIEWS + tableName).pipe(catchError(HttpServiceUtils.handleError));
  }

  deleteView(tableName, viewName) {
    const encodedViewName = encodeURIComponent(viewName);
    return this.http.delete<any>(this.GET_ALL_VIEWS + tableName + '&viewname=' + encodedViewName).pipe(catchError(HttpServiceUtils.handleError));
  }

  setDefaultView(tableName, viewid, standardviewid) {
    const viewIdArg = viewid ? '&viewid=' + viewid : '';
    const standardViewIdArg = standardviewid ? '&standardviewid=' + standardviewid : '';
    return this.http.patch<any>(this.DEFAULT_VIEW + tableName + viewIdArg + standardViewIdArg, {}).pipe(catchError(HttpServiceUtils.handleError));
  }


  getUserViews(): Observable<any> {
    return this.http.get<any>(this.GET_USER_VIEWS).pipe(catchError(HttpServiceUtils.handleError));
  }

  createUserView(view) {
    return this.http.post<any[]>(this.GET_USER_VIEWS, view, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  saveUserView(view, id){
    return this.http.put<any[]>(this.DELETE_USER_VIEW + id, view, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  deleteUserView(id) {
    return this.http.delete<any>(this.DELETE_USER_VIEW + id);
  }

  getGroupings(): Observable<any> {
    return this.http.get<any>(this.GET_GROUPINGS).pipe(catchError(HttpServiceUtils.handleError));
  }

  createGrouping(grouping) {
    return this.http.post<any[]>(this.GET_GROUPINGS, grouping, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  saveGrouping(grouping, id){
    return this.http.put<any[]>(this.GET_GROUPINGS + '/' + id, grouping, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(catchError(HttpServiceUtils.handleError));
  }

  getUnwindPreference() {
    return this.http.get<any>(this.API_ROOT + 'product/unwind/config/currentUser');
  }

  getEmailDeskConfig() {
    return this.http.get<any>(this.API_ROOT + 'product/unwind/config/currentUser/emails');
  }

  usernameSearch(username: string[]) {
    return this.http.post(this.EMAIL_CONFIG + '/user/configs', username);
  }

  postUserConfig(userConfig: any) {
    return this.http.post(this.EMAIL_CONFIG + '/user/config', userConfig);
  }

  getDeskConfigs() {
    return this.http.get<any[]>(this.EMAIL_CONFIG + '/desk/config');
  }

  postDeskConfig(deskConfig: any) {
    return this.http.post(this.EMAIL_CONFIG + '/desk/config', deskConfig);
  }

  getUserDisplayConfig(): Observable<any> {
    return this.http.get<any>(this.DISPLAY_CONFIG + '/user/config');
  }

  getUnwindConfig() {
    return this.http.get<any[]>(this.API_ROOT + 'product/unwind/config');
  }

  postUnwindConfig(productConfig) {
    return this.http.post(this.API_ROOT + 'product/unwind/config/', productConfig);
  }

  saveDisplayPreferences(displayPreferences): Observable<any> {
    return this.http.put(`${this.DISPLAY_CONFIG}/desk/config?deskId=${displayPreferences.deskId}`, displayPreferences);
  }

}
