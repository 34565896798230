import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuPositionY } from '@angular/material/menu/menu-positions';

export interface ISplitMenuItem {
    displayName?: string;
    val: any;
    disabled?: boolean;
}
// TODO Add mandatory selector back in
// selector: 'app-split-button[menuItems]',
@Component({
    selector: 'app-split-button',
    templateUrl: './split-button.component.html',
    styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('splitButtonContainer') container: ElementRef;
    @ViewChild('clickMenuTrigger') test: MatMenuTrigger | undefined;
    @ViewChild('main') main: ElementRef;

    @Input() menuItems: ISplitMenuItem[] = [];
    @Input() mainButtonMenuItem: ISplitMenuItem;
    @Input() position: MenuPositionY = 'above';
    @Input() clickThrough = false;
    @Input() changeOnClickThrough = false;
    @Input() disableSelections = false;
    @Output() menuItemSelected = new EventEmitter<ISplitMenuItem>();

    // TODO - Add disabled state for entire button AND options

    classList: string;

    private largestWidth: number;

    constructor() {
    }

    ngOnInit(): void {
        const menuClass = ['luma-split-button-menu', this.position];

        this.classList = menuClass.join(' ');

        if (!this.mainButtonMenuItem) {
            this.mainButtonMenuItem = this.menuItems?.[0];
        }

        this.largestWidth = this.menuItems.reduce((biggestSize: number, next) => {
            const nextSize = this.calculateSize(next.displayName);
            if (biggestSize < nextSize) {
                return nextSize;
            }

            return biggestSize;
        }, 0);

        // console.log(this.largestWidth);
    }

    ngAfterViewInit(): void {
        this.main.nativeElement.style.width = this.largestWidth + 'px';
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.disableSelections = changes?.disableSelections?.currentValue || this.menuItems.every(menuItem => menuItem.disabled);
    }

    setCurrentMenuItem(menuItem: ISplitMenuItem) {
        if (this.menuItems.includes(menuItem)) {
            this.mainButtonMenuItem = menuItem;
        }
    }

    getCurrentMenuItem() {
        return this.mainButtonMenuItem;
    }

    private calculateSize(inputText) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '14px "Montserrat","Roboto", sans-serif';
        const width = context.measureText(inputText).width;
        const formattedWidth = Math.ceil(width);
        const padding = 24;
        return formattedWidth + padding;
    }

    clickOnMain() {
        this.test?.openMenu();
    }

    onSubMenuClicked(menuItem: ISplitMenuItem) {
        if (this.clickThrough) {
            if (this.changeOnClickThrough) {
                this.mainButtonMenuItem = menuItem;
            }

            if(!menuItem.disabled) {
                this.menuItemSelected.emit(menuItem);
            }

        } else {
            this.mainButtonMenuItem = menuItem;
        }
    }

    testMethod() {
        const margin = 8 * 2;
        const container = document?.getElementById('container');

        const containerWidth = container?.offsetWidth ?? 0;

        const width = containerWidth < this.largestWidth ? this.largestWidth : containerWidth - margin;

        const elemsGet = document.getElementsByClassName('luma-split-button-menu mat-menu-panel');
        const elemsQuery = document.querySelectorAll('.luma-split-button-menu.mat-menu-panel');

        Object.keys(elemsGet).forEach((key, idx) => {
            elemsGet[key].style.width = width + 'px';
        });
    }

}
