import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-currency-code-display',
  templateUrl: './currency-code-display.component.html',
  styleUrls: ['./currency-code-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyCodeDisplayComponent {

  @Input() label: string;
  @Input() currencyCode = 'USD';
  @Input() amount = 0;
  constructor() { }

}
