export enum FilterTypesEnum {
  TEXT_SEARCH,
  TEXT_LIST_SEARCH,
  NUMBER_SEARCH,
  NUMBER_RANGE,
  PERCENT_RANGE,
  YES_NO_BOOLEAN
}

export enum FilterComponents {
  TEXT_SEARCH,
  MULTI_TEXT_SEARCH,
  CHIP_FILTER,
  MULTI_CHIP_FILTER,
  MULTI_SELECT_SEARCH,
  MULTI_SELECT,
  SLIDER_FILTER
}
