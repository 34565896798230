import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AnnuityService } from '@common/services/annuity.service';
import { SpinnerService } from '@common/services/spinner.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  FirelightActivityRequestModel,
  FL_ACORD_TYPES_CODES
} from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-activity-request.model';
import { DOCUMENT } from '@angular/common';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import { AnnuityOrderRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-order-request.model';
import { AnnuityOesRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-oes-request.model';
import { UserService } from '@common/services/user.service';
import { ANNUITY_PRODUCT_TYPES } from '@product-marketplace/annuity-product/annuity-constants';

declare let FireLightAPI: any;

@Component({
  selector: 'app-firelight-embedded',
  templateUrl: './firelight-embedded.component.html',
  styleUrls: ['./firelight-embedded.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FirelightEmbeddedComponent implements OnDestroy, AfterViewInit {
  private FIRELIGHT_EAPP_SCRIPT_URL = '';
  private FIRELIGHT_EAPP_CSS_URL = '';
  private readonly FIRELIGHT_EAPP_SCRIPT_ID = 'eAppScript';
  private readonly FIRELIGHT_EAPP_CSS_ID = 'eAppCSS';

  private FIRELIGHT_ILLUSTRATION_SCRIPT_URL = '';
  private FIRELIGHT_ILLUSTRATION_CSS_URL = '';
  private readonly FIRELIGHT_ILLUSTRATION_ID = 'illustrationScript';
  private readonly FIRELIGHT_ILLUSTRATION_CSS_ID = 'illustrationCSS';

  private readonly FBG_CSS_URL = 'https://cdnjs.cloudflare.com/ajax/libs/flexboxgrid/6.3.1/flexboxgrid.min.css';
  private readonly FBG_CSS_ID = 'fbg';

  flattenedPageMap: any[];
  filteredNav: any[];
  currentPage = 0;
  showPreviousBtn = false;
  showNextBtn = true;

  readonly templates = {
    ILLUSTRATION: 'illustration',
    CLIENT_INTERFACE: 'eAppClientInterface',
  };

  readonly destinations = {
    ILLUSTRATION: 'firelightIllustrationApiDiv',
    CLIENT_INTERFACE: 'firelightClientInterfaceApiDiv',
  };

  loadInterface: string;

  // Init Params
  token = '';
  oesBrokerDealer = '';
  isNew: boolean;
  isClone = false;
  activityId = '';

  // FireLight Illustration API Components
  @ViewChild('btnCreateApp') btnCreateApp: ElementRef;
  @ViewChild('btnUpdateApp') btnUpdateApp: ElementRef;
  @ViewChild('btnOpenQuickView') btnOpenQuickView: ElementRef;
  @ViewChild('firelightIllustrationApiDiv') firelightIllustrationApiDiv: ElementRef;


  // Firelight E-Application API Components
  @ViewChild('firelightClientInterfaceApiDiv') firelightClientInterfaceApiDiv: ElementRef;

  @ViewChild('btnProceedToSignatures') btnProceedToSignatures: ElementRef;
  @ViewChild('btnShowDocumentsDialog') btnShowDocumentsDialog: ElementRef;
  @ViewChild('btnUnlockApplication') btnUnlockApplication: ElementRef;

  percentComplete: number;
  firelightActivityRequestModel: FirelightActivityRequestModel;
  initialStatusLoaded = false;
  initialDrawerOpened = false;
  annuityOesRequestModel: AnnuityOesRequestModel;
  repSelected: any;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data,
              @Inject(DOCUMENT) private doc: Document,
              private dialogRef: MatDialogRef<FirelightEmbeddedComponent>,
              private dialog: MatDialog,
              private annuityService: AnnuityService,
              private userService: UserService,
              private spinnerService: SpinnerService) {
    this.annuityOesRequestModel = this.data.annuityOesRequestModel;
    this.repSelected = this.data.repSelected;
    this.isNew = this.annuityOesRequestModel.isNew;
    this.isClone = this.annuityOesRequestModel.isClone;
    if (!this.isNew || this.isClone) {
      this.activityId = this.annuityOesRequestModel.reference;
    }

    this.loadInterface = this.data?.interface || this.templates.CLIENT_INTERFACE;
    if (this.loadInterface === this.templates.ILLUSTRATION) {
      // Hardcoded data
      this.firelightActivityRequestModel = FirelightActivityRequestModel.fromIllustrationSummary(this.annuityOesRequestModel?.annuityIllustrationSummaryModel);
    } else if (this.loadInterface === this.templates.CLIENT_INTERFACE) {
      this.firelightActivityRequestModel = FirelightActivityRequestModel.fromOesRequestModel(this.annuityOesRequestModel);
      this.firelightActivityRequestModel.acordTypeCode = FL_ACORD_TYPES_CODES.APPLICATION;
    }
  }

  private addEAppScript(dst, onLoad) {
    this.addCss(this.FBG_CSS_URL, this.FBG_CSS_ID, () => {
      this.addCss(this.FIRELIGHT_EAPP_CSS_URL, this.FIRELIGHT_EAPP_CSS_ID, () => {
        this.addScriptWithDestination(this.FIRELIGHT_EAPP_SCRIPT_URL, this.FIRELIGHT_EAPP_SCRIPT_ID, dst, onLoad);
      });
    });
  }

  private addIllustrationScript(dst, onLoad) {
    this.addCss(this.FBG_CSS_URL, this.FBG_CSS_ID, () => {
      this.addCss(this.FIRELIGHT_ILLUSTRATION_CSS_URL, this.FIRELIGHT_ILLUSTRATION_CSS_ID, () => {
        this.addScriptWithDestination(this.FIRELIGHT_ILLUSTRATION_SCRIPT_URL, this.FIRELIGHT_ILLUSTRATION_ID, dst, onLoad);
      });
    });
  }


  private addScriptWithDestination(src: string, dstId: string,  srcId: string, onLoad) {
    const newSrc = this.buildScriptSrcUrl(src, srcId);
    this.addScript(newSrc, dstId, onLoad);
  }

  private addScript(src: string, id: string, onLoad) {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = onLoad;
      document.head.appendChild(script);
    } else {
      onLoad();
    }
  }

  private addCss(href: string, cssId: string, onLoad) {
    if (!document.getElementById(cssId)) {
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = href;
      link.media = 'all';
      link.onload = onLoad;
      document.head.appendChild(link);
    } else {
      onLoad();
    }
  }

  ngAfterViewInit() {
    this.spinnerService.showSpinner();
    this.annuityService.getFirelightBaseUrl().subscribe(baseUrl => {
      this.FIRELIGHT_EAPP_SCRIPT_URL = baseUrl + '/Resource/Scripts/eApp';
      this.FIRELIGHT_EAPP_CSS_URL = baseUrl + '/Resource/CSS/eApp';
      this.FIRELIGHT_ILLUSTRATION_SCRIPT_URL = baseUrl + '/Resource/Scripts/illustration';
      this.FIRELIGHT_ILLUSTRATION_CSS_URL = baseUrl + '/Resource/CSS/illustration';

      const oesVendorName = this.annuityOesRequestModel?.annuityProduct?.oesVendorName;
      const carrier = this.annuityOesRequestModel?.annuityProduct?.carrier;
      this.annuityService.getCarrierInfoFromVendor(oesVendorName, carrier).subscribe(carrierInfo => {
        this.firelightActivityRequestModel.carrierCode = carrierInfo[carrier];
        this.firelightActivityRequestModel.setActivityNameWithCarrier(carrier); // keep here because of timestamp
        this.firelightActivityRequestModel.productType = this.annuityOesRequestModel.annuityProduct.productType === ANNUITY_PRODUCT_TYPES.DIA_SPIA ? ANNUITY_PRODUCT_TYPES.DIA_SPIA : null;
        this.annuityService.getFireLightTokeAndNewActivity(this.firelightActivityRequestModel, (this.isNew && !this.isClone), this.repSelected?.userName).subscribe(resp => {
              this.token = resp.token;
              this.oesBrokerDealer = resp.oesBdName;
              // TODO
              this.activityId = resp.activityId && this.isNew ? resp.activityId : this.activityId;
              if (this.firelightIllustrationApiDiv) {
                this.subscribeToFirelightIllustrationEvents();
              } else if (this.firelightClientInterfaceApiDiv) {
                this.subscribeToEApplicationClientInterface();
              } else {
                this.spinnerService.hideSpinner();
                this.dialogRef.close();
              }
            }, error => {
              this.processErrorEvent(error);
            }
        );
      }, error => {
        this.processErrorEvent(error);
      });

    }, error => {
      this.processErrorEvent(error);
    });
  }

  private getOesBrokerName() {
    return this.annuityOesRequestModel.annuityUserInput.oesBrokerDealerName ?? this.oesBrokerDealer;
  }

  private removeScripts(...scriptIds: string[]) {
    scriptIds.forEach((scriptId: string) => {
      const script = document.getElementById(scriptId);
      if (script) {
        script.parentElement.removeChild(script);
      }
    });
  }

  ngOnDestroy(): void {
    /*
    * This is the only way we can do Applications & Illustrations within the same instance
    * The Illustration script causes issues with E-Application inits
    * */
    this.removeScripts(this.FIRELIGHT_EAPP_SCRIPT_ID, this.FIRELIGHT_ILLUSTRATION_ID);
  }

  onFirelightScriptLoad = (e) => {
  }

  subscribeToFirelightIllustrationEvents() {
    this.firelightIllustrationApiDiv.nativeElement.addEventListener('fireLightLoaded', () => {
      FireLightAPI?.init(this.token, this.activityId, this.isNew);
      FireLightAPI?.linkedApplicationEvent.subscribe(this.linkedApplicationChange);
      FireLightAPI?.errorEvent.subscribe(this.handleErrorEvent);
      FireLightAPI?.statusEvent.subscribe((status) => {
        this.percentComplete = status.percentComplete;
        this.spinnerService.hideSpinner();
      });
      FireLightAPI?.navigationEvent.subscribe(this.navigationChange);
    });

    this.addIllustrationScript(this.destinations.ILLUSTRATION, this.onFirelightScriptLoad);
  }

  linkedApplicationChange = (linkedApp) => {
    console.log('linkedApplicationChange - linkedApp = ' + JSON.stringify(linkedApp));
    if (linkedApp.LastActionSuccess) {
      console.log('Success! The last action: ' + linkedApp.LastAction + ' occurred on ' + linkedApp.LastActionDate);
    } else {
      console.log('Failure! The last action: ' + linkedApp.LastAction + ' occurred on ' + linkedApp.LastActionDate + '. The error message it generated was: ' + linkedApp.LastActionError);
    }
  }

  handleErrorEvent = (err) => {
    // catch CORS error
    if (err.error?.ErrorType === 1) {
      this.processErrorEvent(err);
    } else {
      const errMsgElement = document.getElementById('errMessage');
      if (errMsgElement) {
        const msg = err.error.ApiSource + ' - ' + err.error.ErrorMessage;
        errMsgElement.innerHTML = 'Error message = ' + msg;
      }
    }
  }

  private processErrorEvent(error) {
    this.spinnerService.hideSpinner();
    this.dialogRef.afterClosed().subscribe(event => {
          if (event) {
            // TODO - Handle Error Msg dynamically
            this.dialog.open(ConfirmOrCancelModalComponent, {
              data: {
                message: 'There was an error opening FireLight',
                lumaContact: false,
                singleButton: true,
                title: 'FireLight Error',
              },
              panelClass: ['confirmation-dialog']
            });
          }
        }
    );

    this.dialogRef.close(error);

  }

  subscribeToEApplicationClientInterface() {
    const statusChange =  (status) => {
      if (!this.initialStatusLoaded) {
        const annuityOrderRequestModel = new AnnuityOrderRequestModel();
        annuityOrderRequestModel.reference = this.activityId;
        // Annuity Product
        annuityOrderRequestModel.oesVendor = this.annuityOesRequestModel?.annuityProduct?.oesVendorName;
        annuityOrderRequestModel.oesBrokerDealer = this.oesBrokerDealer ?? this.annuityOesRequestModel?.annuityUserInput?.oesBrokerDealerName;
        annuityOrderRequestModel.cusip = this.annuityOesRequestModel?.annuityProduct?.cusip;
        if (this.annuityOesRequestModel?.annuityProduct?.productType === ANNUITY_PRODUCT_TYPES.DIA_SPIA
            || !this.annuityOesRequestModel?.annuityProduct?.vendorUniqueId) {
          annuityOrderRequestModel.productName = this.annuityOesRequestModel?.annuityProduct?.productName;
          annuityOrderRequestModel.carrier = this.annuityOesRequestModel?.annuityProduct?.carrier;
          annuityOrderRequestModel.annuityType = this.annuityOesRequestModel?.annuityProduct?.productType;
        } else {
          annuityOrderRequestModel.vendorUniqueId = this.annuityOesRequestModel?.annuityProduct?.vendorUniqueId;
        }
        // Annuity User Input
        annuityOrderRequestModel.clientState = this.annuityOesRequestModel?.annuityUserInput?.state;
        annuityOrderRequestModel.premium = this.annuityOesRequestModel?.annuityUserInput?.premium;
        annuityOrderRequestModel.clientAge = this.annuityOesRequestModel?.annuityUserInput?.age;
        // Other Inputs
        annuityOrderRequestModel.transactionTime = new Date().toISOString();
        annuityOrderRequestModel.status = 0;
        annuityOrderRequestModel.eventType = 0;
        annuityOrderRequestModel.financialAdviser = this.repSelected ? this.repSelected?.uuid : this.userService.getUuid();
        annuityOrderRequestModel.financialAdviserName = this.repSelected?.fullName;

        this.annuityService.createInitialOrderEvent(annuityOrderRequestModel).subscribe(ret => {
          this.initialStatusLoaded = true;
          this.spinnerService.hideSpinner();
        }, err => {
          this.spinnerService.hideSpinner();
        });
      }
      this.percentComplete = status.percentComplete;
    };

    const signatureStatusChange = (sig) => {
      if (sig.succeeded) {
        this.annuityService.firelightSingleSignOn(this.activityId, this.getOesBrokerName()).subscribe(resp => {
          const {samlUrl, responseBody } = resp;
          const formPost = document.createElement('form');
          formPost.action = samlUrl;
          formPost.method = 'post';
          formPost.target = '_blank';

          const samlInput = document.createElement('input');
          samlInput.type = 'hidden';
          samlInput.name = 'SAMLResponse';
          samlInput.value = responseBody;

          const relayStateInput = document.createElement('input');
          relayStateInput.type = 'hidden';
          relayStateInput.name = 'RelayState';
          relayStateInput.value = 'eSign';

          formPost.appendChild(samlInput);
          // formPost.appendChild(relayStateInput);
          document.body.appendChild(formPost);
          formPost.submit();
          document.body.removeChild(formPost);
        }, error => console.error(error));
      }
    };

    this.firelightClientInterfaceApiDiv.nativeElement.addEventListener('fireLightLoaded', () => {
      FireLightAPI.init(this.token, this.activityId, this.isNew);
      FireLightAPI.statusEvent.subscribe(statusChange);
      FireLightAPI.signatureStatusEvent.subscribe(signatureStatusChange);
      FireLightAPI.errorEvent.subscribe(this.handleErrorEvent);
      FireLightAPI?.navigationEvent.subscribe(this.navigationChange);
    });

    this.addEAppScript(this.destinations.CLIENT_INTERFACE, this.onFirelightScriptLoad);

  }

  private buildScriptSrcUrl(scriptUrl, renderDestination) {
    return `${scriptUrl}?destination=${renderDestination}`;
  }

  private renderPdf(event) {
    FireLightAPI?.renderPDF();
  }

  private showUnlockDialog() {
    if (this.percentComplete === 100) {
      FireLightAPI?.showUnlockDialog();
    }
  }

  private showDocumentsDialog() {
    FireLightAPI?.showDocumentsDialog();
  }

  // TODO - Need more information from FL crew
  private proceedToSignatures() {
    if (this.percentComplete === 100) {
      FireLightAPI?.proceedToSignatures();
    }
  }

  navigateToPage(pageNumber: number) {
    if (pageNumber !== this.currentPage) {
      FireLightAPI?.navigateToPage(pageNumber);
    }
  }

  navigationChange = (nav) => {
    this.flattenedPageMap = nav.navigationGraph.flatMap(navGroup => {
      return navGroup.IsEnabled ? navGroup.Pages.filter(page => page.IsEnabled) : [];
    });

    this.filteredNav = nav.navigationGraph.filter(navGroup => {
      return navGroup.IsEnabled && navGroup.Pages.filter(page => page.IsEnabled);
    }).map(navGroup => {
      return {
        ...navGroup,
        Pages: navGroup.Pages.filter(page => page.IsEnabled)
      };
    });

    const mapLength = this.flattenedPageMap.length;

    this.currentPage = nav.pageNumber;
    if (mapLength > 0) {
      const lastPageNumber = this.flattenedPageMap[mapLength - 1].PageNumber;
      const firstPageNumber = this.flattenedPageMap[0].PageNumber;

      this.showPreviousBtn = this.currentPage !== firstPageNumber;
      this.showNextBtn = this.currentPage !== lastPageNumber;

      if (!this.initialDrawerOpened) {
        this.initialDrawerOpened = false;
      }

    } else {
      this.showPreviousBtn = false;
      this.showNextBtn = false;
    }
  }

  // TODO - Use navigation calls
  previousPage() {
    if (this.showPreviousBtn) {
      FireLightAPI.save();
      FireLightAPI?.navigateToPagePrevious();
    }
  }

  // TODO - Use navigation calls
  nextPage() {
    if (this.showNextBtn) {
      FireLightAPI.save();
      FireLightAPI?.navigateToPageNext();
    }
  }

  private openQuickView() {
    FireLightAPI?.openQuickView();
  }

  private createApplication() {
    FireLightAPI?.createApplication();
  }

  private updateApplication() {
    FireLightAPI?.updateApplication();
  }

  pageNumber(page) {
    return page.PageNumber;
  }

  close() {
    let message = 'Are you sure you would like to exit the Order Entry Screen?';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
    if (this.isNew) {
      message += ' You can Continue In-Progress applications from the Annuity Order Management tool located on the left side menu.';
    }
    dialogConfig.data =  {
      title: 'Confirm Exit',
      message,
    };
    this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig).afterClosed().subscribe(
      confirm => {
        if (confirm === 'confirm') {
          this.dialogRef.close();
        }
      }
    );
  }
}
