import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection, SortGroup, SortInfo, SortOptions, SortUtilities } from '../../../sort-lib/sort-utilities';

@Component({
  selector: 'app-sort-pills',
  templateUrl: './sort-pills.component.html',
  styleUrls: ['./sort-pills.component.scss']
})
export class SortPillsComponent {
  sortDirections = SortDirection;
  @Input() sortOptions: SortOptions;

  @Input() maxNumberOfSorts = 1;
  @Input() defaultSortDirection: SortDirection = SortDirection.asc;
  defaultViewCount = 5;
  showMore = false;

  @Output() sortChange: EventEmitter<SortOptions> = new EventEmitter<SortOptions>();


  toggleSortOptionSelector(sort) {
    sort.isOpen = !sort.isOpen;
  }

  sortOptionRadioButtonClicked(event, sortGroup: SortGroup, selectedSortInfo: SortInfo): void {
    if (sortGroup.sortInfo !== selectedSortInfo) {
      selectedSortInfo.isSorting = true;
      sortGroup.sortInfo.isSorting = false;
      sortGroup.sortInfo = selectedSortInfo;
      if(sortGroup.sortInfo.sortDirection) {
        sortGroup.sortDirection = sortGroup.sortInfo.sortDirection;
      }
      sortGroup.isOpen = false;
      this.sortChanged();
    }
    event.stopPropagation();
  }

  resetSort(event, sortGroup): void {
    this.showMore = false;
    this.setDefaultGroup();
    this.sortChanged();
    event.stopPropagation();
  }

  updateSort(sortGroup: SortGroup, sortDirection: SortDirection) {
    sortGroup.sortDirection = sortDirection;
    this.sortChanged();
  }

  sortChanged(): void {
    this.sortChange.emit(this.sortOptions);
  }

  closeTheSort(sort) {
    sort.isOpen = false;
  }

  private setDefaultGroup() {
    this.sortOptions.sortGroups.forEach(sortGroup => sortGroup.sortInfo.isSorting = false);
    const defaultGroup = this.sortOptions?.sortGroups.find(sortGroup => sortGroup.isDefaultGroup);
    this.sortOptions.sortGroups = this.sortOptions.sortInfos.filter(sortInfo => sortInfo.isDefault).map(sortInfo => {
      sortInfo.isSorting = true;
      return {
        isOpen: false,
        sortDirection: defaultGroup?.defaultSortDirection ?? this.defaultSortDirection,
        defaultSortDirection: defaultGroup?.defaultSortDirection ?? this.defaultSortDirection,
        sortIndex: 0,
        sortInfo
      };
    });
  }

  addSort() {
    SortUtilities.addSort(this.sortOptions, this.defaultSortDirection);
    this.sortChanged();
  }

  removeSort(index) {
    this.sortOptions.sortInfos.find(sortInfo => sortInfo.sortName === this.sortOptions.sortGroups[index].sortInfo.sortName).isSorting = false;
    SortUtilities.removeSort(this.sortOptions, index);
    this.sortChanged();
  }
}
